<template>
  <div class="form_wrapper">
    <base-title v-if="job_form_specific_text.name" :title_level="block_data.title_level" :text="job_form_specific_text.name"
      :text-align-center="is_mobile_resolution"></base-title>

    <form id="job-form" autocomplete="on">
      <div class="control-group" :class="{ invalid: form.full_name_validity === 'invalid' }">
        <label for="job-form-full-name">
          {{ getLabelText('form_name') }}
        </label>
        <input type="text" name="job-form-full-name" id="job-form-full-name" v-model.trim="form.full_name"
          @blur="validateFullName">
      </div>

      <div class="control-group" :class="{ invalid: form.email_validity === 'invalid' }">
        <label for="job-form-email">
          {{ getLabelText('form_email') }}

        </label>
        <input type="email" name="job-form-email" id="job-form-email" v-model.trim="form.email"
          @blur="validateEmail">
      </div>

      <div class="control-group" :class="{ invalid: form.phone_number_validity === 'invalid' }">
        <label for="job-form-phone">
          {{ getLabelText('form_phone') }}
        </label>
        <input type="number" name="job-form-phone" id="job-form-phone" v-model.trim="form.phone_number"
          @blur="validatePhoneNumber">
      </div>

      <div class="control-group" :class="{ invalid: form.file_validity === 'invalid' }">
        <label v-if="job_form_specific_text.file_label">
          {{ job_form_specific_text.file_label }}*
        </label>
        <div class="custom_input_file">
          <!-- actual upload which is hidden -->
          <input type="file" name="job-form-file" id="job-form-file" hidden ref="job-form-file" @change="onFileChange"/>
          <!-- our custom upload button -->
          <label for="job-form-file">
            <span v-if="job_form_specific_text.file_button">{{ job_form_specific_text.file_button }}</span>
            <arrow-right-svg :color="ui.svg_color_blue" />
          </label>
          <!-- name of file chosen -->
          <span id="file-chosen">{{ form.file.name }}</span>
        </div>        
      </div>

      <div class="control-group privacy" :class="{ invalid: form.privacy_validity === 'invalid' }">
        <input type="checkbox" name="job-form-privacy" id="job-form-privacy" class="privacy_checkbox"
          v-model="form.privacy" @change="validatePrivacy" />
        <label for="job-form-privacy" v-html="custom_field.privacy_policy"></label>
      </div>

      <div class="control-group">
        <button class="button_submit" @click.prevent="sendEmail">
          <span v-if="job_form_specific_text.send_button">{{ job_form_specific_text.send_button }}</span>
          <arrow-right-svg :color="ui.svg_color_white" />
        </button>
      </div>
      <Transition name="fade" >
        <div v-if="ui.notification_visible" class="notification success">
          <p>{{ custom_field.form_notification_success }}</p>
        </div>
      </Transition>
    </form>
  </div>
</template>

<script>

import axios from 'axios';

import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue';

export default {
  name: 'job-form',

  props: ['custom_field', 'is_mobile_resolution', 'job_position'],

  components: { ArrowRightSvg },

  data() {
    return {
      ui: {
        svg_color_blue: '#2C4E9B',
        svg_color_white: '#ffffff',
        notification_visible: false,
      },

      api_email: '/api/sendEmail',
      form_type: 'job',

      default_form_labels: {          
        'form_name': { required: true },
        'form_email': { required: true },
        'form_phone': { required: true },
      },
      job_form_specific_text: null,

      form: {
        full_name: '',
        phone_number: '',
        email: '',
        privacy: false,
        file: '',

        full_name_validity: 'pending',
        phone_number_validity: 'pending',
        email_validity: 'pending',
        privacy_validity: 'pending',
        file_validity: 'pending',
      },
    }
  },

  computed: {

    formValidity: function () {
      let form_validity = true;

      if (!this.validateFullName()) {
        form_validity = false;
      }
      if (!this.validatePhoneNumber()) {
        form_validity = false;
      }
      if (!this.validateEmail()) {
        form_validity = false;
      }
      if (!this.validateFile()) {
        form_validity = false;
      }
      if (!this.validatePrivacy()) {
        form_validity = false;
      }

      return form_validity;
    },
  },

  created() { 
    // console.log('custom_field', this.custom_field); 
    // console.log('default_form_labels', this.default_form_labels);
    // console.log('job_form_specific_text', this.job_form_specific_text);

    this.job_form_specific_text = JSON.parse(this.custom_field.job_form);
  },

  methods: {
    onFileChange(ev) {
      let files = ev.target.files || ev.dataTransfer.files;
      if (!files.length) return;

      this.form.file = files[0];

      this.validateFile();
      console.log(ev, files[0]);
    },

    getLabelText(label_key) {
      let label_text = this.custom_field[label_key];

      if (this.default_form_labels[label_key].required) {
        label_text += '*';
      }

      return label_text;
    },

    validateFullName() {
      if (!this.default_form_labels.form_name.required) {
        return true;
      }

      if (this.form.full_name === '') {
        this.form.full_name_validity = 'invalid';
        return false;
      } else {
        this.form.full_name_validity = 'valid';
        return true;
      }
    },
    validatePhoneNumber() {
      if (!this.default_form_labels.form_phone.required) {
        return true;
      }

      if (this.form.phone_number === '') {
        this.form.phone_number_validity = 'invalid';
        return false;
      } else {
        this.form.phone_number_validity = 'valid';
        return true;
      }
    },
    validateEmail() {
      if (!this.default_form_labels.form_email.required) {
        return true;
      }

      const regex_validate =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (regex_validate.test(this.form.email)) {
        this.form.email_validity = 'valid';
        return true;
      } else {
        this.form.email_validity = 'invalid';
        return false;
      }
    },
    validateFile() {
      if (this.form.file === '') {
        this.form.file_validity = 'invalid';
        return false;
      } else {
        this.form.file_validity = 'valid';
        return true;
      }
    },
    validatePrivacy() {
      if (!this.form.privacy) {
        this.form.privacy_validity = 'invalid';
        return false;
      } else {
        this.form.privacy_validity = 'valid';
        return true;
      }
    },

    resetForm: function () {
      this.form.full_name = '';
      this.form.phone_number = '';
      this.form.email = '';
      this.form.privacy = false;

      this.form.file = '';
      this.$refs['job-form-file'].value = '';
    },

    showNotification() {
      this.ui.notification_visible = true;

      // setTimeout(() => {
      //   this.ui.notification_visible = false;
      // }, 5000)
    },

    sendEmail: function () {
      if (!this.formValidity) {
        return;
      }

      const form_data = new FormData();      
      form_data.append('type', this.form_type);
      form_data.append('name', this.form.full_name);
      form_data.append('email', this.form.email);
      form_data.append('phone', this.form.phone_number);
      form_data.append('form_attachment_file', this.form.file);
      form_data.append('job_position', this.job_position);

      const email_api_url = this.api_email;

      axios({
        method: 'post',
        url: email_api_url,
        data: form_data,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        console.log('Send default form to email response - ', response);

        if (response.data.success  === 'success') {
          console.log('success');

          this.showNotification();
          this.resetForm();

        } else {
          console.log('api error');
        }

      }).catch(error => {
        console.log('Could not perform send email post - ', error);
        console.log('Api error.response.data - ', error.response.data)

        const errors = error.response.data.errors;

        if (errors) {
          if (errors.name) {
            this.form.full_name_validity = 'invalid';
          }
          if (errors.phone) {
            this.form.phone_number_validity = 'invalid';
          }
          if (errors.email) {
            this.form.email_validity = 'invalid';
          }
          if (errors.form_attachment_file) {
            this.form.file_validity = 'invalid';
          }
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.form_wrapper {
  width: 100%;
  max-width: 625px;

  #job-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .control-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;

      &.invalid {

        input,
        textarea {
          border: 1px solid var(--red);
        }

        .custom_input_file {
          label {
            border: 1px solid var(--red);
          }
        }
      }

      &.privacy {
        flex-direction: row;
      }

      label {
        font-size: 16px;
        line-height: 18px;
        font-family: inherit;
        color: #333;

        @media (min-width: 1024px) {
          font-size: 18px;
          line-height: 21px;
        }

        ::v-deep a {
          text-decoration: underline;
        }

        a {
          text-decoration: underline;
        }
      }

      input{
        width: 100%;
        padding: 10px;

        border-radius: 10px;
        border: 1px solid #333;
        background: #FFF;

        font-size: 16px;
        font-family: inherit;
        transition: all 0.3s ease-in-out;

        &:focus,
        &:focus-visible {
          outline: none;
        }
      }      

      .custom_input_file {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--text_color);
        font-size: 16px;
        font-weight: 600;


        @media (min-width: 1024px) {
          font-size: 18px;
        }

        label {
          display: flex;
          padding: 5px 10px;
          align-items: center;
          min-width: fit-content;
          gap: 5px;
          border-radius: 10px;
          border: 1px solid var(--text_color);
          background: #fff;
          color: inherit;

          @media (min-width: 1024px) {
            cursor: pointer;
          }
        }

        span {
          word-break: break-all;
        }
      }

      .privacy_checkbox {
        appearance: none;
        display: grid;
        place-content: center;
        width: 26px;
        height: 26px;
        margin: 0 auto;
        border: 1px solid #333;
        border-radius: 10px;
        background-color: #fff;
        color: #333;
        cursor: pointer;

        &::before {
          content: "";
          width: 15px;
          height: 13px;
          -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          transform: scale(0);
          transform-origin: center;
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em var(--text_color);
        }

        &:checked::before {
          transform: scale(1);
        }
      }      

      .button_submit {
        display: flex;
        padding: 5px 10px;
        align-items: center;
        width: fit-content;
        margin-top: 20px;
        gap: 5px;
        border-radius: 10px;
        border: 1px solid var(--text_color);
        background: var(--text_color);
        color: #fff;
        font-size: 16px;
        font-weight: 600;


        @media (min-width: 1024px) {
          margin-top: 50px;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  }

  .notification {
    margin-bottom: 20px;
    padding: 10px;
    text-align: center;

    p {
      color: #FFF;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.72px;
    }

    &.success {
      background-color: #62AF1D;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>