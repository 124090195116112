<template>
    <div class="content_wrapper">

        <div class="input_wrapper">
            <input v-model="keyword" @keyup.enter="search" type="text" autocomplete="one-time-code"  :placeholder="customFields.search_placeholder + '...'" class="input_search">
            <button v-if="keyword.length" class="btn_clear_search" @click="clearSearchInput">
                <img src="/images/x-circle.svg">
            </button>
        </div>

        <div v-if="is_loading_results">
          <loading-indicator type="spinner"></loading-indicator>
        </div>
        <div v-if="has_errors" class="error_wrapper">
          <p>Loading error</p>
        </div>
        <div v-if="!is_loading_results && !has_errors" class="results_wrapper">            
            <div class="results_header" :class="[all_results.totalHits ? 'results' : 'no_results']">
                <p v-if="all_results.totalHits">{{ results_text }}</p>
                <p v-else>{{ui.no_results_message}}</p>
            </div>
             <div class="results_list">
                <div v-for="(result, result_index) of current_results" :key="result_index" class="result_item">
                    <a :href="result.slug" target="_blank">
                        <h4 v-html="result.title" class="title"></h4>
                        <p v-html="result.preview" class="content_preview"></p>
                    </a>
                </div>
             </div>
        </div>

        <div v-if="!is_loading_results && !has_errors && pagination.total_pages > 1" class="pagination_wrapper" >
          <button class="btn-pagination" :disabled="pagination.current_page === 1" @click="goToFirstPage">
            <double-angle-left-svg :color="ui.pagination_svg_color"/>
          </button>
          <button class="btn-pagination" :disabled="pagination.current_page === 1" @click="goToPreviousPage">
            <angle-left-svg :color="ui.pagination_svg_color"/>
          </button>

          <button v-for="page in display_pages" :key="page" :class="{ active_page: pagination.current_page === page, 'btn-pagination': typeof page !== 'string' }" 
            @click="goToPage(page)">
            {{ page }}
          </button>

          <button class="btn-pagination" :disabled="pagination.current_page === pagination.total_pages" @click="goToNextPage" >
            <angle-right-svg :color="ui.pagination_svg_color"/>
          </button>
          <button class="btn-pagination" :disabled="pagination.current_page === pagination.total_pages" @click="goToLastPage">
            <double-angle-right-svg :color="ui.pagination_svg_color"/>
          </button>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import LoadingIndicator from './common/LoadingIndicator.vue';
import AngleRightSvg from './svg-components/AngleRightSvg.vue';
import AngleLeftSvg from './svg-components/AngleLeftSvg.vue';
import DoubleAngleRightSvg from './svg-components/DoubleAngleRightSvg.vue';
import DoubleAngleLeftSvg from './svg-components/DoubleAngleLeftSvg.vue';

export default {
    name: 'search-component',

    props: ["currentLanguage", 'customFields'],

    components: {
        LoadingIndicator,
        AngleRightSvg,
        AngleLeftSvg,
        DoubleAngleRightSvg,
        DoubleAngleLeftSvg,
    },

    data() {
        return {
            ui: {
                no_results_message: "",
                pagination_svg_color: '#95A6CC',
            },            

            keyword: "",

            products: {
                api: "/api/search-product",
                results: { 
                    data: [], 
                    totalHits: 0 
                },
                is_loading: false,
                has_errors: false
            },

            pages: {
                api: "/api/search-page",
                results: {
                    data: [],
                    totalHits: 0
                },
                is_loading: false,
                has_errors: false
            },

            pagination: {
                current_page: 1,
                total_pages: 0,
                results_per_page: 10,
            },            
        };
    },

    computed: {
        is_loading_results() {
           return this.products.is_loading || this.pages.is_loading;
        },

        has_errors() {
            return this.products.has_errors && this.pages.has_errors;
        },

        all_results() {

            let data = [];
            let totalHits = 0;

            if (this.pages.results.data || this.products.results.data) {
               data = [...this.pages.results.data, ...this.products.results.data];
            }

            if (this.pages.results.totalHits > 0 || this.products.results.totalHits > 0) {
                totalHits = this.pages.results.totalHits + this.products.results.totalHits;
            }

            return {
                data,
                totalHits
            }
        },

        results_text() {
            return this.customFields.search_results + ' ' + '(' + this.all_results.totalHits + ')';
        },

        current_results() {

            let start = (this.pagination.current_page - 1) * this.pagination.results_per_page;
            let end = start + this.pagination.results_per_page;

            return this.all_results.data.slice(start, end);

        },

        display_pages() {

            let total_pages = this.pagination.total_pages;
            let page = this.pagination.current_page;
            let pages = [];

            if (total_pages <= 6) {
                pages = total_pages;
            } else {

                if (page < total_pages - 2) {
                    if (page == 1) {
                        page = page + 1;
                    }
                    pages = [page - 1, page, page + 1, '...', total_pages - 1, total_pages];

                } else {
                    pages = [1, 2, '...', total_pages - 2, total_pages - 1, total_pages];
                }
            }

            return pages;
        },
    },

    methods: {
        clearSearchInput() {
            this.keyword = '';
            this.ui.no_results_message = "";

            this.pages.results.data = [];
            this.pages.results.totalHits = 0;

            this.products.results.data = [];
            this.products.results.totalHits = 0;

            this.setTotalPages();
        },

        search() {

            if ( this.keyword.length == 0 ) {
                this.ui.no_results_message = this.customFields.search_input_empty;
                return
            } else {
                this.ui.no_results_message = this.customFields.search_no_results;
            }
            
            this.getSearchResults(this.pages);
            this.getSearchResults(this.products);
        },

        getSearchResults(request) {
            
            const form_data = new FormData();
            form_data.append("keyword", this.keyword);
            form_data.append("language", this.currentLanguage);

            request.is_loading = true;
            request.has_errors = false;

            axios({
                method: "post",
                url: request.api,
                data: form_data,
            }).then((response) => {

                request.results = { ...response.data };
                request.is_loading = false;

                this.setTotalPages();

            })
            .catch((error) => {

                request.is_loading = false;
                request.has_errors = true;
            });
        },

        // pagination 
        setTotalPages() {
            this.pagination.total_pages = Math.ceil(this.all_results.data.length / this.pagination.results_per_page);
        },

        goToFirstPage() {
            this.pagination.current_page = 1;
        },

        goToPreviousPage() {
            this.pagination.current_page--;
        },

        goToPage(page_number) {

            if (typeof page_number === 'string') {
                return
            }
            this.pagination.current_page = page_number;
        },

        goToNextPage() {
            this.pagination.current_page++;
        },

        goToLastPage() {
            this.pagination.current_page = this.pagination.total_pages;
        },
    },
};
</script>

<style lang="scss" scoped>

.input_wrapper {
    display: flex;
    align-items: center;
    width: 300px;
    height: 44px;
    margin-bottom: 30px;
    border: 1px solid #333333;
    border-radius: 10px;
    background-color: transparent;

    @media (max-width: 768px) {
        width: 100%;
    }

    .input_search {
        width: 100%;
        height: 100%;
        padding: 10px;
        background-color: transparent;
        border: none;
        color: #333333;
        caret-color: #333333;
        font-family: inherit;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        outline: none;

        &::placeholder {
            color: #333333;
        }
    }

    .btn_clear_search {
        display: flex;
        height: 100%;
        padding: 10px;
        border: none;
        background: transparent;
        outline: none;
        cursor: pointer;
    }
}

.error_wrapper {
    display: flex;
    justify-content: center;
}

.results_wrapper {
    color: #333333;

    .results_header {

        &.results {
            margin-bottom: 30px;  
            padding-bottom: 10px;
            border-bottom: 1px dashed #0000001A;

            p {
              font-size: 18px;
                line-height: 21px;
                font-weight: 700;
                font-family: inherit;  
            }
            
        }

        &.no_results {
            p {
                font-size: 16px;
                line-height: 18px;
                font-weight: 400;
                font-family: inherit;
            }    
        }
    }

    .results_list {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .result_item {
            border-bottom: 1px dashed #0000001A;
            padding-bottom: 20px;

            .title {
                margin-bottom: 10px;

                font-size: 16px;
                line-height: 18px;
                font-weight: 700;
                font-family: inherit;
            }

            .content_preview {

                font-size: 16px;
                line-height: 18px;
                font-weight: 400;
                font-family: inherit;

                &::v-deep {
                    em {
                        color: var(--red);
                    }
                }
            }
        }
    }
}


.pagination_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    @media (min-width: 1024px) {
      gap: 10px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;

      font-size: 18px;
      color: #95A6CC;
    }

    .btn-pagination {  
      cursor: pointer;

      &.active_page {
        border: 1px solid #95A6CC;
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }


</style>
