import { cookiesConsent } from './helpers/cookies_consent';

import Vue from "vue";

import $ from 'jquery';
import 'slick-carousel';

window.jQuery = window.$ = $;

window.Vue = Vue;

// Vue components
import BaseTitle from './components/common/BaseTitle.vue';
Vue.component('base-title', BaseTitle);

import AngleRightSvg from './components/svg-components/AngleRightSvg.vue';
Vue.component('angle-right-svg', AngleRightSvg);

import HeroSlider from './components/HeroSlider.vue'
Vue.component('hero-slider', HeroSlider);

import SectionSlider from './components/SectionSlider.vue'
Vue.component('section-slider', SectionSlider);

import SectionSliderImages from './components/SectionSliderImages.vue';
Vue.component('section-slider-images', SectionSliderImages);

import PostSlider from './components/PostSlider.vue'
Vue.component('section-post-slider', PostSlider);

import SectionGridManagement from './components/SectionGridManagement.vue'
Vue.component('section-grid-management', SectionGridManagement);

import SectionTestimonials from './components/SectionTestimonials.vue'
Vue.component('section-testimonials', SectionTestimonials);

import PaginationImagesGrid from './components/PaginationImagesGrid.vue';
Vue.component('pagination-images-grid', PaginationImagesGrid);

import PaginationPostsGrid from './components/PaginationPostsGrid.vue';
Vue.component('pagination-posts-grid', PaginationPostsGrid);

import PaginationBrochuresGrid from './components/PaginationBrochuresGrid.vue';
Vue.component('pagination-brochures-grid', PaginationBrochuresGrid);

import SectionText from './components/SectionText.vue';
Vue.component('section-text', SectionText);

import SectionTitle from './components/SectionTitle.vue';
Vue.component('section-title', SectionTitle);

import SectionVideoPopup from './components/SectionVideoPopup.vue';
Vue.component('section-video-popup', SectionVideoPopup);

import NewsPage from './components/NewsPage.vue';
Vue.component('news-page', NewsPage);

import SectionConsultants_Distributors from './components/SectionConsultants_Distributors.vue';
Vue.component('section-consultants-distributors', SectionConsultants_Distributors);

import ContactPage from './components/ContactPage.vue';
Vue.component('contact-page', ContactPage);

import JobPage from './components/JobPage.vue';
Vue.component('job-page', JobPage);

import SectionComplaint from './components/SectionComplaint.vue';
Vue.component('section-complaint', SectionComplaint);

import SectionInternship from './components/SectionInternship.vue';
Vue.component('section-internship', SectionInternship);

import SectionSponsorship from './components/SectionSponsorship.vue';
Vue.component('section-sponsorship', SectionSponsorship);

import SectionProductsGrid from './components/SectionProductsGrid.vue';
Vue.component('section-products-grid', SectionProductsGrid);

import SectionCategoryProductsGrid from './components/SectionCategoryProductsGrid.vue';
Vue.component('section-category-products-grid', SectionCategoryProductsGrid);

import ProductPage from './components/ProductPage.vue';
Vue.component('product-page', ProductPage);

import SectionProductCategory from './components/SectionProductCategory.vue';
Vue.component('section-product-category', SectionProductCategory);

import SectionFertilizerSeller from './components/SectionFertilizerSeller.vue';
Vue.component('section-fertilizer-seller', SectionFertilizerSeller);

import SearchComponent from './components/SearchComponent.vue';
Vue.component('search-component', SearchComponent);

const app = new Vue({
    el: '#app',

    directives: {
        hero_component: {
            inserted: (el) => {
                if (el) {
                    const heroComponentElement = document.querySelector('#heroComponent');
                    const heroComponentParentElement = heroComponentElement.parentElement;

                    document.querySelector('.q10__header').insertAdjacentElement('afterend', heroComponentElement);
                    heroComponentParentElement.remove();
                }
            },
            unbind: (el) => {
                el.remove()
            }
        },
    },

    mounted() {
        const self = this;
        self.setLayout();
        window.addEventListener('resize', self.setLayout);
    },

    beforeDestroy() {
        const self = this;
        window.removeEventListener('resize', self.setLayout);
    },

    methods: {
        setLayout() {
            const self = this;

            const content_container = self.$el.querySelector('.q10__content');
            const all_section_container = self.$el.querySelectorAll('.q10__section');
            const footer = self.$el.querySelector('.q10__footer');
            const content_post_category = self.$el.querySelector('.post_category');
            const all_post_category_container = self.$el.querySelectorAll('.post_category');
            const section_alternate_overlay = self.$el.querySelector('.overlay')
            let footer_overlay = self.$el.querySelector('.footer_overlay')
            const breadcrumbs_container = self.$el.querySelector('.breadcrumbs')

            // Construim fisierul style
            const style_id = 'dynamic-footer-style';
            let style_tag = document.getElementById(style_id);

            if (!style_tag) {
                style_tag = document.createElement('style');
                style_tag.id = style_id;
                document.head.appendChild(style_tag);
            }

            if (content_container && footer) {

                const footerBeforeHeight = window.getComputedStyle(footer, '::before').height;

                if (!breadcrumbs_container) {
                    content_container.style.marginTop = '20px'
                }

                if (all_section_container && all_section_container.length) {

                    const section_container = all_section_container[all_section_container.length - 1];

                    let section_container_wrappers =  section_container.querySelectorAll('.content_wrapper');
                    let section_slider_wrappers =  section_container.querySelectorAll('.q10__section_slider');

                    if (section_container_wrappers && section_container_wrappers.length) {
                       let section_container_last_wrapper = section_container_wrappers[section_container_wrappers.length - 1]
                       section_container_last_wrapper.style.marginBottom = '20px';
                    } else {
                       let section_slider_last_wrapper = section_slider_wrappers[section_slider_wrappers.length - 1]
                       section_slider_last_wrapper.style.marginBottom = '20px';
                    }

                    let last_section_has_alternate = all_section_container[all_section_container.length - 1].classList.contains('alternate');

                    // const section_last_div = section_container.lastChild;
                    // if (section_last_div) {
                    //     const section_last_div_bg_color = window.getComputedStyle(section_last_div).backgroundColor;

                    //     content_container.style.marginBottom = `calc(${footerBeforeHeight} - 0px`; // am lasat calc pentru a manipula mai usor valoarea in pixeli

                    //     style_tag.innerHTML = `
                    //         .${footer.className}::before {
                    //             background-color: ${section_last_div_bg_color};
                    //             top: -${footerBeforeHeight};
                    //         }
                    //     `;
                    // }

                    if (section_container) {
                        let section_last_div_bg_color = '';

                        if (last_section_has_alternate) {
                            section_last_div_bg_color = window.getComputedStyle(section_alternate_overlay).backgroundColor;
                            footer_overlay.style.backgroundColor = section_last_div_bg_color;
                        } else {
                            section_last_div_bg_color = window.getComputedStyle(section_container).backgroundColor;
                            footer_overlay.style.backgroundColor = section_last_div_bg_color;
                        }

                        content_container.style.marginBottom = `calc(${footerBeforeHeight} - 0px`; // am lasat calc pentru a manipula mai usor valoarea in pixeli

                        style_tag.innerHTML = `
                            .${footer.className}::before {
                                background-color: ${section_last_div_bg_color};
                                top: -${footerBeforeHeight};
                                transform: translateY(0);
                            }
                        `;
                    }
                } else {
                    style_tag.innerHTML = `
                        .${footer.className}::before {
                            top: -${footerBeforeHeight};
                            transform: translateY(0)
                        }
                    `;
                }
            }

            if (content_post_category && footer) {

                const footerBeforeHeight = window.getComputedStyle(footer, '::before').height;

                if (all_post_category_container && all_post_category_container.length) {

                    const section_container = all_post_category_container[all_post_category_container.length - 1];
                    const section_last_div = section_container.lastChild;

                    if (section_last_div) {
                        const section_last_div_bg_color = window.getComputedStyle(section_last_div).backgroundColor;

                        content_post_category.style.marginBottom = `calc(${footerBeforeHeight} - 0px`; // am lasat calc pentru a manipula mai usor valoarea in pixeli

                        style_tag.innerHTML = `
                            .${footer.className}::before {
                                background-color: ${section_last_div_bg_color};
                                top: -${footerBeforeHeight};
                                transform: translateY(0);
                            }
                        `;
                    }
                } else {
                    style_tag.innerHTML = `
                        .${footer.className}::before {
                            top: -${footerBeforeHeight};
                            transform: translateY(0);
                        }
                    `;
                }
            }

        }
    },
})

window.app = app;

// ! Cookies consent
const cookiesPopup = document.querySelector('#cookie');
const allowBtnCookies = document.querySelector('.cookie_consent_btn');
const cookiesName = 'azomures';

if (cookiesPopup && allowBtnCookies && cookiesName) {
    cookiesConsent(cookiesPopup, allowBtnCookies, cookiesName);
}

// function scrollTop() {
//     const scrollTop = document.querySelector(".scrolltop");
//     if (this.scrollY >= 560) scrollTop.classList.add("show-scroll");
//     else scrollTop.classList.remove("show-scroll");
// }
// window.addEventListener("scroll", scrollTop);

const buttonBurger = document.querySelector(".q10__header__top__burger_menu");
const burgerImage = document.querySelector(".q10__header__top__burger");
const burgerMenu = document.querySelector(".q10__header__popup");
const targetMenu = document.querySelector(".dropdown-content")
const selectMenu = document.querySelector("#selected")
const angleDown = document.querySelector(".angle_down");

buttonBurger.addEventListener("click", (e) => {
    if (!burgerMenu.classList.contains("active")) {
        burgerMenu.classList.add("active");
        burgerImage.classList.add("active")
        document.body.style.overflow = "hidden";
    } else {
        burgerMenu.classList.remove("active");
        document.body.style.overflow = "auto"
        burgerImage.classList.remove("active");
    }

    if (burgerImage.classList.contains('active')) {
        burgerImage.src = "/images/close.svg";
    } else {
        burgerImage.src = "/images/burger_menu.svg";
    }
});

window.openLang = function () {
    if (targetMenu.classList.contains('show')) {
        targetMenu.classList.remove('show');
        selectMenu.style = "box-shadow: 0;"
        angleDown.style.transform = "rotate(0deg)";
        angleDown.style.left = "0px";
    } else {
        targetMenu.classList.add('show');
        angleDown.style.transform = "rotate(180deg)";
        angleDown.style.left = "-5px";
    }
}

window.addEventListener("openLang", openLang);

document.addEventListener("click", (e) => {
    const isClosest = e.target.closest('#selected');

    if (!isClosest && targetMenu.classList.contains("show")) {
        targetMenu.classList.remove("show");
    }
});

if (window.matchMedia("(min-width: 1024px)").matches) {

    let childrenArray = Array.prototype.slice.apply(document.querySelectorAll('.item_children'));

    childrenArray.some(function (el) {
        let parentChildren = Array.prototype.slice.call(el.children)
        parentChildren.forEach(element => {
            const parentElement = element.closest(".item_parent");
            if (element.classList.contains('item_selected')) {
                parentElement.classList.add('item_selected')
            }
        });
    });

}