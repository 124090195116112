<template>
    <div class="content_wrapper">
        <div class="job_wrapper">
            <base-title v-if="block_data.title" :title_level="block_data.title_level" :text="block_data.title"></base-title>

            <div class="job_container">
                <div v-if="block_data.position.length" class="job_item row">
                    <p class="job_subtitle">{{ custom_field.position_career }}</p>
                    <p class="job_description">{{ block_data.position }}</p>
                </div>
                <div v-if="block_data.career_level.length" class="job_item row">
                    <p class="job_subtitle">{{ custom_field.career_level }}</p>
                    <p class="job_description">{{ block_data.career_level }}</p>
                </div>
                <div v-if="block_data.job_type.length" class="job_item row">
                    <p class="job_subtitle">{{ custom_field.job_type_career }}</p>
                    <p class="job_description">{{ block_data.job_type }}</p>
                </div>
                <div v-if="block_data.work_place.length" class="job_item row">
                    <p class="job_subtitle">{{ custom_field.work_place_career }}</p>
                    <p class="job_description">{{ block_data.work_place }}</p>
                </div>
                <div v-if="block_data.salary.length" class="job_item row">
                    <p class="job_subtitle">{{ custom_field.salary }}</p>
                    <div class="job_description" v-html="block_data.salary"></div>
                </div>
                <div v-if="block_data.job_description.length" class="job_item column">
                    <p class="job_subtitle">{{ custom_field.job_description }}</p>
                    <div class="job_description wysiwyg" v-html="block_data.job_description"></div>
                </div>
                <div v-if="block_data.ideal_candidate.length" class="job_item column">
                    <p class="job_subtitle">{{ custom_field.ideal_candidate_career }}</p>
                    <div class="job_description wysiwyg" v-html="block_data.ideal_candidate"></div>
                </div>
                <div v-if="block_data.skills.length" class="job_item column">
                    <p class="job_subtitle">{{ custom_field.skills_career }}</p>
                    <div class="job_description wysiwyg" v-html="block_data.skills"></div>
                </div>
                <div v-if="block_data.job_responsibility.length" class="job_item column">
                    <p class="job_subtitle">{{ custom_field.job_responsibility_career }}</p>
                    <div class="job_description wysiwyg" v-html="block_data.job_responsibility"></div>
                </div>
                <div v-if="block_data.benefits.length" class="job_item column">
                    <p class="job_subtitle">{{ custom_field.benefits }}</p>
                    <div class="job_description wysiwyg" v-html="block_data.benefits"></div>
                </div>
            </div>
        </div>

        <job-form :custom_field="custom_field" :is_mobile_resolution="ui.is_mobile_resolution" :job_position="block_data.position"></job-form>
    </div>
</template>

<script>
import BaseTitle from "./common/BaseTitle.vue";
import JobForm from './forms/JobForm.vue';

export default {
    name: 'job-page',

    props: ['block_data', 'custom_field'],

    components: {
        BaseTitle,
        JobForm
    },

    data() {
        return {
            ui: {
                is_mobile_resolution: false,
                is_desktop_resolution: false,
            },
        }
    },

    computed: { },

    created() {
        console.log('block_data', this.block_data);
    },

    mounted() {
        let self = this;

        window.addEventListener('resize', self._onResize);
        self._onResize();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this._onResize);
    },

    methods: {
        _onResize: function () {
            this._updateResolutionType();
        },

        _updateResolutionType: function () {

            if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
                this.ui.is_mobile_resolution = true;
                this.ui.is_desktop_resolution = false;
            } else {
                this.ui.is_mobile_resolution = false;
                this.ui.is_desktop_resolution = true;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.content_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: unset;
        justify-content: space-between;
    }

    .job_wrapper {
        width: 100%;
        max-width: 625px;

        .job_container {
            display: flex;
            flex-direction: column;
            gap: 10px;            

            .job_item:nth-child(even) {
                background: rgba(175, 175, 175, 0.15);
            }

            .job_item {
                padding: 5px 10px;

                color: #333;
                font-size: 16px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.64px;

                &.row {
                    p {
                        display: inline;
                    }
                }

                &.column {

                    .job_subtitle {
                        margin-bottom: 20px;
                    }

                    .job_description {        

                        &.wysiwyg::v-deep {
                            ul {
                                list-style-type: disc;
                                margin-left: 2rem;
                            }
                        }
                    }
                }

                .job_subtitle {
                    font-weight: 500;           
                }

                .job_description {
                    font-weight: 400;
                }
            }
        }
    }
}    
</style>