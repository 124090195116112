<template>
    <div class="content_wrapper">
        <base-title v-if="block_data.title" :title_level="block_data.title_level" :text="block_data.title" :text-align-center="ui.is_mobile_resolution"></base-title>

        <div v-if="ui.is_loading_posts">
          <loading-indicator type="spinner"></loading-indicator>
        </div>
        <div v-if="ui.no_posts" class="error_wrapper">
          <p>Error</p>
        </div>
        
        <div v-if="!ui.is_loading_posts && !ui.no_posts" class="slider_wrapper">
            <div :id="carousel_id">
                <template v-if="posts.template === 'news'">
                    <news-card v-for="(post, index) in posts.values" :key="index" :post_content="post.content" :post_slug="post.slug" :storage_path="storage_path" :cache_custom_field="custom_field" :fallback-logo="true"
                    >
                    </news-card>
                </template>
                <template v-else-if="posts.template === 'career'">
                    <job-card v-for="(post, index) in posts.values" :key="index" :data="post" :cache_custom_field="custom_field"
                    >
                    </job-card>
                </template>
                <template v-else-if="posts.template === 'testimonial'">
                    <testimonial-card  v-for="(post, index) in posts.values" :key="index" :post_content="post.content" :post_slug="post.slug" :storage_path="storage_path" :cache_custom_field="custom_field"
                    >
                    </testimonial-card>
                </template>
            </div>
        </div>

        <div v-if="!ui.is_loading_posts && !ui.no_posts && block_data.text_button" class="more_posts">
            <a :href="posts.slug">
                {{ block_data.text_button }}
                <arrow-right-svg :color="ui.svg_color" :slim="true"/>
            </a>
        </div>
    </div>
</template>

<script>
import axios from "axios";

import BaseTitle from './common/BaseTitle.vue';
import LoadingIndicator from './common/LoadingIndicator.vue';

import NewsCard from './cards/NewsCard.vue';
import JobCard from './cards/JobCard.vue';
import TestimonialCard from './cards/TestimonialCard.vue';

import ArrowRightSvg from './svg-components/ArrowRightSvg.vue';

export default {
    name: 'section-post-slider',

    props: ['image_path', 'storage_path', 'block_data', 'custom_field'],

    components: {
        BaseTitle,
        LoadingIndicator,

        NewsCard,
        JobCard,
        TestimonialCard,

        ArrowRightSvg,
    },

    data() {
        return {
            ui: {
                is_loading_posts: false,
                no_posts: false,
                is_mobile_resolution: false,
                is_desktop_resolution: false,
                svg_color: '#333333',
            },

            carousel_id: '',
            slick_carousel_initialized: false,

            posts_base_api: '/api/getPostByCategoryId/',
            posts: {},
        }
    },

    computed: {
    },

    created() {

        // console.log(this.block_data);

        this.getPosts();
    },

    mounted() { },

    updated() {
        if (!this.ui.is_loading_posts && !this.ui.no_posts && !this.slick_carousel_initialized) {
            this.initSlickCarousel();
            this.slick_carousel_initialized = true;
        }
    },

    methods: {
        getPosts() {
            var self = this;

            const post_category = self.block_data.post_categories;
            const posts_base_api = self.posts_base_api;
            
            const posts_api = posts_base_api + post_category;

            self.ui.is_loading_posts = true;

            axios.get(posts_api)
                .then((response) => {
                    if (response.data.values.length) {

                        let data = response.data;

                        for (let key in data) {

                            Vue.set(self.posts, key, data[key])
                        }

                        // console.log('getPosts', self.posts);
                        this.setCarouselId();

                    } else {

                        self.ui.no_posts = true;

                    }

                    self.ui.is_loading_posts = false;

                }).catch((error) => {

                    self.ui.no_posts = true;
                    self.ui.is_loading_posts = false;
                })
        },

        setCarouselId() {

            this.carousel_id = 'posts-' + this.block_data.post_categories + '-' + Date.now();
        },

        initSlickCarousel() {

            const id_selector = '#' + this.carousel_id;

            // console.log('initSlickCarousel', document.querySelector(id_selector));

            $(id_selector).slick({
                infinite: false,
                dots: false,
                arrows: true,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1366,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            dots: false,
                            arrows: true
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                            arrows: false
                        }
                    },
                ],
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.slider_wrapper {
    position: relative;
    width: 100%;

    @media (min-width: 1024px) {
        // padding: 20px 0;
    }
}

.more_posts {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    
    @media (min-width: 1024px) {
        padding-top: 50px;
    }

    a {  
        display: flex;
        align-items: center; 
        padding: 5px 10px;
        font-size: 16px;
        line-height: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.64px;
        color: #333333; 
        cursor: pointer;
    }
}
</style>