<template>
  <div class="category_products_card">
    <a :href="category_data.slug" class="category_products_card_link">
      <div class="category_products_card_content">

        <div class="category_products_card_image">
          <base-image :imgSrc="getImageSrc(category_data.images)"></base-image>
        </div>

        <div class="category_products_card_body">

          <div class="category_products_text">
            <p class="category_name">{{ category_data.name }}</p>
            <div class="category_description" v-html="category_data.description"></div>
          </div>

          <div class="category_products_action">
            <div class="category_products--see-products">
              <p>{{ custom_fields.see_all_products }}</p>
              <arrow-right-svg :color="svg_color"></arrow-right-svg>
            </div>
          </div>
        </div>       
      </div>
    </a>
  </div>
</template>

<script>

import BaseImage from "../common/BaseImage.vue";
import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue';


export default {
  name: 'category-products-card',

  props: ['category_data', 'storage_path', 'custom_fields'],

  components: { BaseImage, ArrowRightSvg },

  data() {
    return {
      svg_color: '#333333',
    }
  },

  created() {
    // console.log(this.category_data);
  },

  mounted() { },

  methods: {

    getImageSrc(images) {

      if (images.length) {

        const image_url = images[0].file_name;

        if (image_url) {
          if (image_url.indexOf('http') !== -1) {
            return image_url;
          } else {
            return this.storage_path + '/' + image_url;
          }
        } else {
          return '';
        }

      } else {
        return '';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.category_products_card {
  padding: 20px;

  @media (min-width: 1024px) {
    padding: 25px;
  }

  .category_products_card_link {
    display: flex;
    height: 100%;
  }

  .category_products_card_content {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    margin: 0 auto;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
  }

  .category_products_card_image {
    width: 100%;
    height: 250px;
  }

  .category_products_card_body {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 22px;
    flex-grow: 1;

    @media (min-width: 1024px) {
      gap: 32px;
    }

    .category_products_text {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex-grow: 1;

      .category_name {
        color: var(--text_color);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.72px;

        @media (min-width: 1024px) {
          font-size: 18px;
        }
      }

      .category_description {
        color: #333;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.72px;

        @media (min-width: 1024px) {
          font-size: 18px;
        }
      }
    }

    .category_products_action {

      .category_products--see-products {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        background-color: rgba(175, 175, 175, 0.15);

        color: #333333;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.8px;

        @media (min-width: 1024px) {
          font-size: 18px;
        }
      }
    }
  }
}
</style>