<template>
  <div class="brochure_card">

    <base-title v-if="brochure_data.brochure_title" :title_level="brochure_data.title_level" :text="brochure_data.brochure_title" :text-align-center="true" :text-size="titleSize"></base-title>

    <div class="brochure_card_content">

      <a v-if="brochure_data.image" class="brochure_card_image" :href="getLink()" :download="addDownload" :target="addTarget">
        <base-image :imgSrc="getImageSrc(brochure_data.image)"></base-image>
      </a>

      <div class="brochure_card_action">
        <a class="brochure_card--text-btn" :href="getLink()" :download="addDownload" :target="addTarget">
          {{ brochure_data.text_button }}
        </a>        

        <div class="brochure_card--action">
          <a v-if="brochure_data.document" :href="getDocumentSrc(brochure_data.document)" :download="brochure_data.document">
            <download-svg color="#AFAFAF"/>
          </a>

          <template v-if="brochure_data.link_type == 'internal'">
            <a v-if="brochure_data.internal_link !== null" :href="internal_link_slug">
              <arrow-right-svg :color="svg_color" />
            </a>
          </template>

          <template v-if="brochure_data.link_type == 'external'">
            <a v-if="brochure_data.external_link" :href="brochure_data.external_link" target="_blank">
              <arrow-right-svg  :color="svg_color" />
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

import BaseImage from "../common/BaseImage.vue";
import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue';
import DownloadSvg from '../svg-components/DownloadSvg.vue'


export default {
  name: 'brochure-card',

  props: [
    'storage_path',
    'brochure_data',
    'current_language',
    'desktop_resolution'
  ],

  components: { BaseImage, ArrowRightSvg, DownloadSvg },

  data() {
    return {
      svg_color: '#2C4E9B',
      pages_array: null,
      internal_link_slug: null,
      internal_link_id: null,

      has_document: false,
      has_link: false,
    }
  },

  computed: {
    addDownload() {
      if (this.has_document && !this.has_link) {
        return this.brochure_data.document
      } else {
        return false
      }
    },

    addTarget() {
      if (this.brochure_data.link_type == 'external') {
        return '_blank'
      } else {
        return false
      }
    },

    titleSize() {
      if (this.desktop_resolution) {
        return this.brochure_data.brochure_title_size;
      }
    }
  },

  created() {
    console.log(
      // 'storage_path', this.storage_path,
      // 'brochure_data', this.brochure_data,
      // 'current_language', this.current_language
      );

    if (this.brochure_data.document) {
      this.has_document = true;
    }

    if (this.brochure_data.link_type == 'internal' || this.brochure_data.link_type == 'external') {
      this.has_link = true;
    }
  },

  mounted() {
    if (this.brochure_data.link_type == 'internal') {
      this.getInternalLink();
    }
  },

  methods: {

    getLink() {

      // console.log('getLink')

      let document_link = this.getDocumentSrc(this.brochure_data.document);

      let site_link;
      if(this.brochure_data.internal_link !== null && this.brochure_data.link_type == 'internal') {
        site_link = this.internal_link_slug;
      }
      if (this.brochure_data.external_link && this.brochure_data.link_type == 'external') {
        site_link = this.brochure_data.external_link;
      }

      if(this.has_document && this.has_link) {
        return site_link;
      } else if (this.has_link) {
        return site_link;
      } else if (this.has_document) {
        return document_link;
      }

    },

    getImageSrc(image_url) {

      if (image_url) {

        if (image_url.indexOf('http') !== -1) {
          return image_url;
        } else {
          return this.storage_path + '/' + image_url;
        }

      } else {
        return '';
      }
    },

    getDocumentSrc(document_url) {

      if (document_url) {

        if (document_url.indexOf('http') !== -1) {
          return document_url;
        } else {
          return this.storage_path + '/' + document_url;
        }

      } else {
        return '';
      }
    },

    getInternalLink() {
      const self = this;

      if (self.current_language) {
        axios.get("/api/getPages?language=" + self.current_language).then((response) => {
          self.pages_array = response.data;

          if (self.brochure_data) {
            self.internal_link_id = self.brochure_data.internal_link;
            self.internal_link_slug = self.pages_array[self.internal_link_id]
          }
        });
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.brochure_card {
  display: flex;
  flex-direction: column;
  max-width: 375px;
  padding: 20px 15px;

  @media (min-width: 1024px) {
    padding: 15px;
  }

  .brochure_card_content {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
  }

  .brochure_card_image {
    display: block;
    width: 100%;
    height: 250px;
  }

  .brochure_card_action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 20px;
    flex-grow: 1;
  }

  .brochure_card--text-btn {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.64px;
    color: var(--text_color);
  }

  .brochure_card--action {
    display: flex;
    gap: 5px;

    a {
      width: 24px;
      height: 24px;
    }

  }
}
</style>