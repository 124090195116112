<template>
    <div ref="slider" class="hero_slider_wrapper">
        <div class="hero_polygons">
            <img :src="image_path +  '/polygons.svg'" />
        </div>

        <transition-group tag="div" :name="'slide-' + direction" class="hero_slider" mode="out-in">
            <template v-for="(slide, slide_index) in slider_data">
                <div :key="slide_index" :id="`slide-${slide_index}`" :ref="`slide-${slide_index}`"
                    class="hero_slide" 
                    v-show="slide_index === current_index"
                    @touchstart="touchStart"
                    @touchmove="touchMove"
                    @touchend="touchEnd">
                    <div class="hero_slide_image">
                        <img :src="storage_file_path + '/' + slide.image" :alt="alt_image(slide.image)">
                    </div>
                    <div :ref="`slideDetails-${slide_index}`" class="hero_slide_details">
                        <div class="hero_slide_details_title">
                            <h1 :ref="`slideTitle-${slide_index}`" v-html="slide.title" :style="titleStyleObject(slide)"></h1>
                        </div>
                        <div class="hero_slide_details_subtitle">
                            <p :ref="`slideSubtitle-${slide_index}`" v-html="slide.subtitle" :style="subtitleStyleObject(slide)"></p>
                        </div>
                        <div class="hero_slide_details_text">
                            <p :ref="`slideText-${slide_index}`" v-html="slide.text" :style="textStyleObject(slide)"></p>
                        </div>
                        <div class="hero_slide_details_link">
                            <!-- <div class="hero_slider_link">
                                <a :ref="`slideBtn-${slide_index}`" v-if="slider_data[current_index].internal_link !== null" :href="internal_link_slug" :style="btnStyleObject(slide)">
                                    {{slide.label_button}}
                                    <ArrowRightSvg :size="ui.font_size_btn" :color="'#2C4E9B'"/>
                                </a>
                            </div> -->
                                <div class="hero_slider_link">
                                    <template v-if="slider_data[current_index].link_type == 'internal'" >
                                            <a :ref="`slideBtn-${slide_index}`" v-if="slider_data[current_index].internal_link !== null" :href="internal_link_slug" :style="btnStyleObject(slide)">
                                                {{slide.label_button}}
                                                <ArrowRightSvg :size="ui.font_size_btn" :color="'#2C4E9B'" />
                                            </a>
                                    </template>
                                    
                                    <template v-if="slider_data[current_index].link_type == 'external'">
                                        <a :ref="`slideBtn-${slide_index}`" v-if="slider_data[current_index].external_link" :href="slider_data[current_index].external_link" :style="btnStyleObject(slide)">
                                            {{slide.label_button}}
                                            <ArrowRightSvg :color="'#2C4E9B'" />
                                        </a>
                                    </template>
                                </div>
                        </div>
                    </div>
                </div>
            </template>
        </transition-group>

        <!-- <div class="hero_slider_link">
            <a :href="storage_file_path + '/' + slider_data[current_index].link">
                {[storage_file_path + '/' + slider_data[current_index].link]}
            </a>
        </div> -->

        <!-- <button class="nav-button prev" @click="prevSlide">Previous</button>
        <button class="nav-button next" @click="nextSlide">Next</button> -->

        <div class="hero_options">
            <div class="dots_navigation" v-if="slider_data.length > 1">
                <div class="outer_dot" v-for="(slide, index) in slider_data" :key="index" @click="goToSlide(index)" :class="{ active_slide: current_index === index }">
                    <div class="inner_dot"></div>
                </div>
            </div>

            <div class="hero_slider_down">
                <img :src="image_file_path + '/chevron-down.svg'" alt="">
            </div>
        </div>
        

    </div>
</template>

<script>
    import ArrowRightSvg  from './svg-components/ArrowRightSvg.vue'
    import axios from "axios";

    export default {
        name: 'hero-slider',

        props: ['image_path', 'storage_path', 'slider','current_language'],

        components: {
            ArrowRightSvg
        },

        computed: {
            image_file_path() {
                const self = this;
                return self.image_path;
            },

            storage_file_path() {
                const self = this;
                return self.storage_path;
            },

            slider_data() {
                const self = this;
                return self.slider;
            },
        },

        data() {
            return {
                observer: null,
                current_index: 0,
                direction: 'next',
                is_animating: false,
                auto_slide: {
                    interval: null,
                    seconds: 5000,
                    active: this.slider.length > 1 ? true : false,
                },
                start_x: 0,
                start_y: 0,
                end_x: 0,
                end_y: 0,
                pages_array: null,
                internal_link_id: null,
                internal_link_slug: null,

                ui: {
                    is_mobile_resolution: false,
                    is_desktop_resolution: false,

                    font_size_title: 31,
                    max_font_size_title: 120,
                    font_size_subtitle: 26,
                    max_font_size_subtitle: 80,
                    font_size_text: 20,
                    max_font_size_text: 55,
                    font_size_btn: 16,
                    line_height_factor: 1.1,
                },

                fs_title_array: [],
                fs_subtitle_array: [],
                fs_text_array: [],
            }
        },

        created() {
            const self = this;
            // console.log('slider', self.slider);

            self.checkMatchMedia();
            window.addEventListener('resize', self.onResize);
        },

        methods: {
            onResize: function () {
			    this.checkMatchMedia();
                this.setSliderTextSize()
            },

            checkMatchMedia: function () {
                const self = this;

                if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
                    self.ui.is_mobile_resolution = true;
                    self.ui.is_desktop_resolution = false;
                } else {
                    self.ui.is_mobile_resolution = false;
                    self.ui.is_desktop_resolution = true;
                }
            },

            nextSlide() {
                const self = this;
                if (self.is_animating) return;
                self.is_animating = true;
                self.direction = 'next';
                self.current_index = (self.current_index + 1) % self.slider_data.length;

                if (self.slider_data) {
                    self.getInternalLink();
                }

                this.resetAutoSlide();

                setTimeout(() => {
                    this.is_animating = false;
                }, 550);
            },

            prevSlide() {
                const self = this;
                if (self.is_animating) return;
                self.is_animating = true;
                self.direction = 'prev';
                self.current_index = (self.current_index - 1 + self.slider_data.length) % self.slider_data.length;

                if (self.slider_data) {
                    self.getInternalLink();
                }

                this.resetAutoSlide();

                setTimeout(() => {
                    this.is_animating = false;
                }, 550);
            },

            goToSlide(index) {
                const self = this;
                if (self.current_index < index) {
                    self.direction = 'next';
                }
                if (self.current_index > index) {
                    self.direction = 'prev';
                }

                self.current_index = index;

                if (self.slider_data) {
                    self.getInternalLink();
                }
                self.resetAutoSlide();
            },

            startAutoSlide() {
                if (this.auto_slide.interval) {
                    clearInterval(this.auto_slide.interval);
                }

                this.auto_slide.interval = setInterval(() => {
                    this.nextSlide()
                }, this.auto_slide.seconds);
            },

            stopAutoSlide() {
                if (this.auto_slide.interval) {
                    clearInterval(this.auto_slide.interval);
                }
            },

            resetAutoSlide() {
                if (this.auto_slide.active) {
                    this.stopAutoSlide();
                    this.startAutoSlide()
                }
            },

            touchStart(event) {
                this.start_x = event.touches[0].clientX;
                this.start_y = event.touches[0].clientY;
            },

            touchMove(event) {
                this.end_x = event.touches[0].clientX;
                this.end_y = event.touches[0].clientY;
            },

            touchEnd(event) {
                let x_difference;
                let y_difference;

                let threshold = 50;

                if (this.end_x === 0) {
                    x_difference = 0
                } else {
                    x_difference = this.start_x - this.end_x;
                }

                if (this.end_y === 0) {
                    y_difference = 0
                } else {
                    y_difference = this.start_y - this.end_y;
                }

                if (Math.abs(x_difference) > Math.abs(y_difference)) {
                    if (x_difference > threshold) {
                        this.nextSlide()
                    } else if (x_difference < -threshold) {
                        this.prevSlide()
                    }
                }

                this.start_x = 0;
                this.start_y = 0;
                this.end_x = 0;
                this.end_y = 0;
            },
            getInternalLink() {
                const self = this;
                if (self.current_language) {
                    axios.get("/api/getPages?language=" + self.current_language).then((response) => {
                        self.pages_array = response.data;

                        // console.log("self.current_index",self.current_index);
                        if (self.slider_data[self.current_index]) {
                            self.internal_link_id = self.slider_data[self.current_index].internal_link;
                            self.internal_link_slug = self.pages_array[self.internal_link_id]

                        }
                    });
                }
            },

            setSliderTextSize() {
                this.$nextTick(() => {
                    let text_container = this.$refs[`slideDetails-${this.current_index}`][0];

                    let slide_title = this.$refs[`slideTitle-${this.current_index}`][0];
                    let slide_subtitle = this.$refs[`slideSubtitle-${this.current_index}`][0];
                    let slide_text = this.$refs[`slideText-${this.current_index}`][0];
                    let slide_btn = this.$refs[`slideBtn-${this.current_index}`][0];

                    this.slider_data.forEach(element => {
                        this.fs_title_array.push(element.font_size_title);
                        this.fs_subtitle_array.push(element.font_size_subtitle);
                        this.fs_text_array.push(element.font_size_text);

                    });

                    if (this.slider_data[this.current_index].font_size_title > this.ui.max_font_size_title) {
                        this.calculateSliderTextSize(text_container, slide_title, 'font_size_title', 22, this.ui.max_font_size_title);
                    } else {
                        this.calculateSliderTextSize(text_container, slide_title, 'font_size_title', 22, this.fs_title_array[this.current_index]);
                    }
                    if (this.slider_data[this.current_index].font_size_subtitle > this.ui.max_font_size_subtitle) {
                        this.calculateSliderTextSize(text_container, slide_subtitle, 'font_size_subtitle', 18, this.ui.max_font_size_subtitle);
                    } else {
                        this.calculateSliderTextSize(text_container, slide_subtitle, 'font_size_subtitle', 18, this.fs_subtitle_array[this.current_index]);
                    }
                    if (this.slider_data[this.current_index].font_size_text > this.ui.max_font_size_text) {
                        this.calculateSliderTextSize(text_container, slide_text, 'font_size_text', 14, this.ui.max_font_size_text);
                    } else {
                        this.calculateSliderTextSize(text_container, slide_text, 'font_size_text', 14, this.fs_text_array[this.current_index]);
                    }
                    this.calculateSliderBtnTextSize(text_container, slide_btn, 'font_size_btn', 14, 35);

                    // this.beta_calculateSliderTextSize(text_container, slide_title, 'font_size_title', 24, 90);
                    // this.beta_calculateSliderTextSize(text_container, slide_text, 'font_size_text', 16, 50);
                    // this.beta_calculateSliderTextSize(text_container, slide_btn, 'font_size_btn', 16, 50);
                })
            },

            beta_calculateResolutionFactor() {
                let screen_width = window.innerWidth;
                let screen_height = window.innerHeight;
                let base_width = 1920;
                let base_height = 1080;

                return Math.sqrt((screen_width * screen_height) / (base_width * base_height));
            },

            beta_calculateDynamicFontSize(resolutionFactor, minFontSize, maxFontsize) {
                let dynamic_fs = (maxFontsize - minFontSize) * resolutionFactor;
                return Math.max(Math.min(dynamic_fs, maxFontsize), minFontSize);
            },

            beta_calculateSliderTextSize(textContainer, element, fontSizeProperty, minFontSize, maxFontsize) {
                let text_container_width = textContainer.clientWidth;
                let text_container_height = textContainer.clientHeight;

                let resolution_factor = this.beta_calculateResolutionFactor();
                let fs = this.beta_calculateDynamicFontSize(resolution_factor, minFontSize, maxFontsize);

                let max_allowable_fs = Math.min(text_container_width / 10, text_container_height / 5);
                fs = Math.min(fs, max_allowable_fs);

                this.ui[fontSizeProperty] = fs;

                this.$nextTick(() => {
                    if (element.scrollWidth > text_container_width || element.scrollHeight > text_container_height) {
                        while ((element.scrollWidth > text_container_width || element.scrollHeight > text_container_height) && fs > minFontSize) {
                            fs -= 1;
                            this.ui[fontSizeProperty] = fs;
                        }
                    }
                })
            },

            calculateSliderTotalOccupiedWidth(textContainer) {
                let total_width = 0;
                Array.from(textContainer.children).forEach(child => {
                    total_width += child.offsetWidth + parseInt(getComputedStyle(child).marginLeft) + parseInt(getComputedStyle(child).marginRight) + parseInt(getComputedStyle(child).paddingLeft) + parseInt(getComputedStyle(child).paddingRight)
                })
                return total_width;
            },

            calculateSliderTotalOccupiedHeight(textContainer) {
                let total_height = 0;
                Array.from(textContainer.children).forEach(child => {
                    total_height += child.offsetHeight + parseInt(getComputedStyle(child).marginTop) + parseInt(getComputedStyle(child).marginBottom) + parseInt(getComputedStyle(child).paddingTop) + parseInt(getComputedStyle(child).paddingBottom)
                })
                return total_height;
            },

            calculateSliderTextSize(textContainer, element, fontSizeProperty, minFontSize, maxFontsize) {
                let total_occupied_width = this.calculateSliderTotalOccupiedWidth(textContainer);
                let total_occupied_height = this.calculateSliderTotalOccupiedHeight(textContainer);

                let text_container_width = textContainer.clientWidth;
                let text_container_height = textContainer.clientHeight;

                let effective_text_container_height = this.ui.is_desktop_resolution ? textContainer.clientHeight : (textContainer.clientHeight - total_occupied_height);

                let max_allowable_fs = Math.min(text_container_width / 10, effective_text_container_height / 5);
                let capped_max_fs = Math.min(maxFontsize, max_allowable_fs)

                let base_width = 1920;
                let base_height = 1080;

                let width_scale_factor = text_container_width / base_width;
                let height_scale_factor = effective_text_container_height / base_height;

                let scale_factor = Math.min(width_scale_factor, height_scale_factor);

                let fs = scale_factor * capped_max_fs + minFontSize;

                fs = Math.max(minFontSize, Math.min(fs, capped_max_fs));

                this.ui[fontSizeProperty] = fs;
                
                this.$nextTick(() => {
                    if (element.scrollWidth > text_container_width || element.scrollHeight > effective_text_container_height) {
                        while ((element.scrollWidth > text_container_width || element.scrollHeight > effective_text_container_height) && fs > minFontSize) {
                            fs -= 1;
                            this.ui[fontSizeProperty] = fs;
                        }
                    }
                });
            },

            calculateSliderBtnTextSize(textContainer, element, fontSizeProperty, minFontSize, maxFontsize) {
                let total_occupied_height = this.calculateSliderTotalOccupiedHeight(textContainer);

                let text_container_width = textContainer.clientWidth;

                let effective_text_container_height = this.ui.is_desktop_resolution ? textContainer.clientHeight : (textContainer.clientHeight - total_occupied_height);

                let max_allowable_fs = Math.min(text_container_width / 10, effective_text_container_height / 5);
                let capped_max_fs = Math.min(maxFontsize, max_allowable_fs)

                let base_width = 1920;
                let base_height = 1080;

                let width_scale_factor = text_container_width / base_width;
                let height_scale_factor = effective_text_container_height / base_height;

                let scale_factor = Math.min(width_scale_factor, height_scale_factor);

                let fs = scale_factor * (capped_max_fs - minFontSize) + minFontSize;

                fs = Math.max(minFontSize, Math.min(fs, capped_max_fs));

                this.ui[fontSizeProperty] = fs;

                this.$nextTick(() => {
                    if (element.scrollWidth > text_container_width || element.scrollHeight > effective_text_container_height) {
                        while ((element.scrollWidth > text_container_width || element.scrollHeight > effective_text_container_height) && fs > minFontSize) {
                            fs -= 1;
                            this.ui[fontSizeProperty] = fs;
                        }
                    }
                });
            },

            titleStyleObject(slide_data) {

                if (this.ui.is_mobile_resolution) {
                    return {
                            // fontSize: `${this.ui.font_size_title}px`,
                            // lineHeight: `${this.ui.font_size_title * this.ui.line_height_factor}px`,
                            '-webkit-text-fill-color': slide_data.color_title,
                            '-webkit-text-stroke': `1px ${slide_data.color_title}`,
                        } 
                } else {
                    if (!this.is_animating) {
                        return {
                            fontSize: `${this.fs_title_array[this.current_index]}px`,
                            lineHeight: `${this.fs_title_array[this.current_index] * this.ui.line_height_factor}px`,
                            '-webkit-text-fill-color': slide_data.color_title,
                            '-webkit-text-stroke': `1px ${slide_data.color_title}`,
                        } 
                    } else {
                        return {
                            opacity: '0'
                        }
                    }
                }
               
                // const fontSize = typeof slide_data.font_size_title === 'string' ? parseInt(slide_data.font_size_title) : slide_data.font_size_title;

                // console.log('titleStyleObject | fontSize', fontSize);

                // const lineHeight = Math.round(fontSize + (10 * fontSize / 100));

                // if (this.ui.is_desktop_resolution) {
                //     return {
                //         fontSize: fontSize + 'px',
                //         '-webkit-text-fill-color': slide_data.color_title,
                //         lineHeight: lineHeight + 'px',
                //     }
                // } else {
                //     return {
                //         '-webkit-text-fill-color': slide_data.color_title,
                //     }
                // }
            },

            subtitleStyleObject(slide_data) {

                if (this.ui.is_mobile_resolution) {
                    return {
                            fontSize: `${this.ui.font_size_subtitle}px`,
                            lineHeight: `${this.ui.font_size_subtitle * this.ui.line_height_factor}px`,
                            '-webkit-text-fill-color': slide_data.color_title,
                            '-webkit-text-stroke': `1px ${slide_data.color_title}`,
                        } 
                } else {
                    if (!this.is_animating) {
                        return {
                            fontSize: `${this.fs_subtitle_array[this.current_index]}px`,
                            lineHeight: `${this.fs_subtitle_array[this.current_index] * this.ui.line_height_factor}px`,
                            '-webkit-text-fill-color': slide_data.color_subtitle,
                            '-webkit-text-stroke': `1px ${slide_data.color_subtitle}`,
                        }
                    } else {
                        return {
                            opacity: '0'
                        }
                    }
                }
                
                // const fontSize = typeof slide_data.font_size_title === 'string' ? parseInt(slide_data.font_size_title) : slide_data.font_size_title;

                // console.log('titleStyleObject | fontSize', fontSize);

                // const lineHeight = Math.round(fontSize + (10 * fontSize / 100));

                // if (this.ui.is_desktop_resolution) {
                //     return {
                //         fontSize: fontSize + 'px',
                //         '-webkit-text-fill-color': slide_data.color_title,
                //         lineHeight: lineHeight + 'px',
                //     }
                // } else {
                //     return {
                //         '-webkit-text-fill-color': slide_data.color_title,
                //     }
                // }
            },

            textStyleObject(slide_data) {

                if (this.ui.is_mobile_resolution) {
                    return {
                            fontSize: `${this.ui.font_size_text}px`,
                            lineHeight: `${this.ui.font_size_text * (this.ui.line_height_factor + (this.ui.is_desktop_resolution ? 0.3 : 0))}px`,
                            '-webkit-text-fill-color': slide_data.color_text,
                        } 
                } else {
                    if (!this.is_animating) {
                        return {
                            fontSize: `${this.fs_text_array[this.current_index]}px`,
                            lineHeight: `${this.fs_text_array[this.current_index] * (this.ui.line_height_factor + (this.ui.is_desktop_resolution ? 0.3 : 0))}px`,
                            '-webkit-text-fill-color': slide_data.color_text,
                        }
                    } else {
                        return {
                            opacity: '0'
                        }
                    }
                }

                // const fontSize = typeof slide_data.font_size_text === 'string' ? parseInt(slide_data.font_size_text) : slide_data.font_size_text;

                // console.log('textStyleObject | fontSize', fontSize);

                // const lineHeight = Math.round(fontSize + (10 * fontSize / 100));

                // if (this.ui.is_desktop_resolution) {
                //     return { 
                //         fontSize: fontSize + 'px', 
                //         '-webkit-text-fill-color': slide_data.color_text,
                //         lineHeight: lineHeight + 'px',
                //     }
                // } else {
                //     return {
                //         '-webkit-text-fill-color': slide_data.color_text,
                //     }
                // }
            },

            btnStyleObject(slide_data) {
                if (!this.is_animating) {
                    return {
                        fontSize: `${this.ui.font_size_btn}px`,
                        lineHeight: `${this.ui.font_size_btn * this.ui.line_height_factor}px`,
                    }
                } else {
                    return {
                        opacity: '0',
                    }
                }
            },

            isSliderInView(entries, observer) {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.auto_slide.active = true;
                        this.startAutoSlide();
                    } else {
                        this.auto_slide.active = false;
                        this.stopAutoSlide();
                    }
                })
            },

            alt_image(slide_image) {
                let index = slide_image.lastIndexOf("/") + 1;
                let filename = slide_image.substr(index);

                let img_alt = '';

                if (slide_image.includes('_')) {
                    img_alt = filename.split('.')[0].replaceAll("_"," ");
                }

                if (slide_image.includes('-')) {
                    img_alt = filename.split('.')[0].replaceAll("-"," ");
                }
                return img_alt;
            }
        },

        mounted() {
            if (this.auto_slide.active) {
                this.observer = new IntersectionObserver(this.isSliderInView, {
                    root: null,
                    threshold: 0.5
                })

                this.$nextTick(() => {
                    this.observer.observe(this.$refs.slider)
                })
            }

            if (this.slider_data) {
                this.getInternalLink();
                this.setSliderTextSize()
            }
        },

        beforeDestroy() {
            const self = this;
            self.stopAutoSlide();
            window.removeEventListener('resize', self.onResize);

            if(self.observer) {
                self.observer.disconnect();
            }
        },
    }
</script>

<style lang="scss" scoped>
    .hero_slider_wrapper {
        // height: calc(100svh - var(--header_mobile_height));
        height: 100svh; // mobile support
        position: relative;
        color: #FFF;
        text-align: center;
        font-family: var(--default-font);
        overflow: hidden;

        @media (min-width: 1024px) {
            // height: calc(100vh - var(--header_desktop_height)); // fallback desktop
            height: 100vh;
        }
        .hero_slider {
            position: relative;
            height: 100%;
            width: 100%;
            .hero_slide {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                &_image {
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                    }
                }

                &_details {
                    position: absolute;
                    top: 98px;
                    left: 50%;
                    bottom: 55px;
                    transform: translate(-50%, 0%);
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    overflow: hidden;
                    padding: 0 1rem;

                    @media (min-width: 1024px) {
                        top: 155px;
                        bottom: 100px;
                        padding: 0;
                        width: 80%;
                    }

                    &_title {
                        margin-top: auto;
                        p {
                            line-height: 37px;

                            font-family: var(--default-font);
                            font-weight: 800;
                            letter-spacing: 1.36px;

                            @media (min-width: 376px) {
                                font-size: 34px;
                            }

                            @media (min-width: 1024px) {
                                // font-size: calc(2.125rem + ((1vw - 7.68px) * 3.9931));
                                font-weight: 700;
                            }
                        }
                    }

                    &_subtitle {
                        margin-top: 10px;
                        p {
                            line-height: 37px;

                            font-family: var(--default-font);
                            font-weight: 800;
                            letter-spacing: 1.36px;

                            @media (min-width: 1024px) {
                                // font-size: calc(2.125rem + ((1vw - 7.68px) * 3.9931));
                                font-weight: 900;
                            }
                        }
                    }

                    &_text {
                        margin-top: 20px;
                        top: 10%;
                        position: relative;

                        // for really small devices
                        @media (orientation: landscape) and (max-width: 660px) {
                            margin-top: 10px;
                        }

                        @media (min-width: 1024px) {
                            margin-top: 35px;
                        }

                        p {
                            font-size: 20px;
                            line-height: 24px;

                            font-family: var(--default-font);
                            font-weight: 600;
                            letter-spacing: 1.36px;
                            // -webkit-text-stroke: 1px #707070;

                            @media (min-width: 1024px) {
                                font-weight: 700;
                            }
                        }
                    }

                    &_link {
                        margin-top: auto !important; // am folosit !important ca sa suprascrie media queries pe care nu le-am sters, in caz de revenim la design-ul vechi cu margin-top de 20px
                        margin-bottom: 20px;

                        // for really small devices
                        @media (orientation: landscape) and (max-width: 660px) {
                            margin-top: 10px;
                        }
                        
                        @media (min-width: 1024px) {
                            margin-top: 35px;
                        }

                        a {
                            color: #2C4E9B;
                            padding: 10px 20px;
                            border-radius: 10px;
                            background: #FFF;
                            border: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #2C4E9B;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            letter-spacing: 0.64px;
                            cursor: pointer;

                            // for really small devices
                            @media (orientation: landscape) and (max-width: 660px) {
                                padding: 5px 10px;
                            }
                        }
                    }
                }
            }

            #slide-0 {
                .hero_slide_details {
                    &_title {
                        p {
                            @media (max-width: 1023px) {
                                font-size: 22px !important;
                                line-height: 1.1;
                            }
                        }
                    }
                }
            }

            #slide-1 {
                .hero_slide_details {
                    &_title {
                        padding-left: 10%;
                        width: 100%;
                        position: relative;

                        p {
                            text-align: left;

                            @media (max-width: 1023px) {
                                font-size: 26px !important;
                                line-height: 1.1;
                            }
                        }
                    }
                    &_subtitle {
                        p {
                            text-align: left;
                        }
                    }
                }
            }

            #slide-2 {
                .hero_slide_details {
                    &_title {
                        p {
                            text-align: left;
                            font-weight: 900;
                            -webkit-text-stroke: unset;
                            top: 40%;
                            position: relative;

                            @media (max-width: 1023px) {
                                top: 80%;
                                left: 10%;
                                font-size: clamp(14px, 10vw, 18px) !important;
                                line-height: 1.1;
                            }
                        }
                    }
                    &_subtitle {
                        p {
                            text-align: left;
                        }
                    }
                }
            }
        }

        .hero_polygons {
            display: none;

            @media (min-width: 1024px) {
                display: block;
                position: absolute;
                left: 0;
                background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.75) 20%, rgba(255, 255, 255, 0.25) 40%, rgba(255, 255, 255, 0) 100%);
                top: 0;
                width: 100%;
                z-index: 1;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .hero_options {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .dots_navigation {
                // position: absolute;
                // bottom: 45px;
                // left: 50%;
                // transform: translate(-50%, -50%);
                display: flex;
                justify-content: center;
                align-items: center;

                // @media (min-width: 1024px) {
                //     bottom: 68px;
                // }

                .outer_dot {
                    border: 1px solid #2C4E9B;
                    width: 18px;
                    height: 18px;
                    background-color: #ffffff;
                    border-radius: 50%;
                    position: relative;
                    cursor: pointer;

                    @media (min-width: 1024px) {
                        width: 32px;
                        height: 32px;
                    }

                    @media (min-width: 1366px) {
                        width: 36px;
                        height: 36px;
                    }

                    &:not(:last-of-type) {
                        margin-right: 10px;
                    }

                    &.active_slide {
                        .inner_dot {
                            transition: all 0.3s ease-in-out;
                            background-color: #2C4E9B;
                            opacity: 1;
                        }
                    }
                }

                .inner_dot {
                    position: absolute;
                    transition: all 0.3s ease-in-out;
                    border: 1px solid transparent;
                    background-color: #ffffff;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;

                    @media (min-width: 1024px) {
                        width: 14px;
                        height: 14px;
                    }

                    @media (min-width: 1366px) {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .hero_slider_down {
                // position: absolute;
                // bottom: 13px;
                // left: 50%;
                // transform: translate(-50%, -50%);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 42px;
                margin: 1rem 0 5px 0;

                @media (min-width: 1024px) {
                    width: 104px;
                    margin: 2rem 0 5px 0;
                }

                img {
                    width: 100%;
                    display: block;
                    object-fit: contain;
                }
            }
        }

    }

    .slide-next-enter-active, 
    .slide-next-leave-active, 
    .slide-prev-enter-active, 
    .slide-prev-leave-active {
        transition: all 0.5s;
    }
    .slide-next-enter {
        transform: translate(100%, 0%);
    }
    .slide-next-leave-to {
        transform: translate(-100%, 0%);
    }
    .slide-prev-enter {
        transform: translate(-100%, 0%);
    }
    .slide-prev-leave-to {
        transform: translate(100%, 0%);
    }

</style>