<template>
    <div class="card_wrapper">

        <a :href="card.slug" class="job_card">
            <div>
                <h2 class="card_title">{{ card.content.title }}</h2>
                <hr class="card_dashed_line">
            </div>
            <div class="card_text">
                <div class="card_details" v-if="card.content.career_level">
                    <p class="card_subtitle">{{ custom_field.career_level }}</p>
                    <p class="card_description">{{ card.content.career_level }}</p>
                </div>
                <div class="card_details" v-if="card.content.job_type">
                    <p class="card_subtitle">{{ custom_field.job_type_career }}</p>
                    <p class="card_description">{{ card.content.job_type }}</p>
                </div>
                <div class="card_details" v-if="card.content.work_place.length">
                    <p class="card_subtitle">{{ custom_field.work_place_career }}</p>
                    <p class="card_description">{{ card.content.work_place }}</p>
                </div>
            </div>
            <div class="card_button">
                <hr class="card_dashed_line">

                <span>
                    {{ custom_field.see_details_and_apply }}
                    <arrow-right-svg :color="'#2C4E9B'" />
                </span>
            </div>
        </a>
    </div>
</template>

<script>
import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue'

export default {
    name: 'job-card',

    props: ['posts_count', 'specific_posts', 'text_button', 'data', 'cache_custom_field', 'style_width'],

    components: {
        ArrowRightSvg
    },

    computed: {

        card() {
            const self = this;
            // console.log("self.data", self.data);

            return self.data;
        },

        custom_field() {
            const self = this;
            // console.log(self.cache_custom_field);

            return self.cache_custom_field;
        }
    },

    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.card_wrapper {
    position: relative;
    display: flex !important;
    flex-direction: column;
    width: 100%;
    max-width: 474px;

    margin: 0 auto;
    height: 100%;

    .card_content {
        border-radius: 10px;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
        overflow: hidden;
    }

    .job_card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
        background: #fff;
        height: 100%;

        .card_title {
            color: var(--text_color);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.64px;
            margin-bottom: 10px;
        }

        .card_text {
            &>div {
                position: relative;
                padding: 3px 10px;
            }

            &>div:nth-child(odd) {
                background: rgba(175, 175, 175, 0.15);
            }

            .card_details {
                &>p {
                    color: #333;
                    font-size: 16px;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.64px;
                }

                .card_subtitle {
                    font-weight: 500;
                    display: inline;
                }

                .card_description {
                    font-weight: 400;
                    display: inline;
                }
            }
        }

        .card_dashed_line {
            border: none;
            border-top: 1px dashed #AFAFAF;
            width: 100%;
            margin: 1px 0;
        }

        .card_button {
            display: flex;
            gap: 5px;
            width: 100%;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;

            span {
                padding: 5px 0px;
                display: flex;
                align-items: center;
                color: var(--text_color);
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.64px;
                border: none;
                background: none;
                cursor: pointer;
                width: 100%;
            }
        }
    }
}
</style>