<template>
    <div>
        <div class="q10__section padding">
            <div class="content_wrapper product_page_upper_content">
                <div class="product_page_info">
                    <div class="product_page_labels">
                        <div v-if="product_logo" class="product_page_logo">
                            <img :src="product_logo" />
                        </div>
                        <base-title v-else-if="productData.name" :title_level="'1'" :text="productData.name" class="no-padding"></base-title>
                        <div v-if="product_label" class="product_page_label">
                            <span>{{ product_label }}</span>
                        </div>
                    </div>

                    <div v-if="productData.description" class="product_page_description wysiwyg" v-html="productData.description"></div>
                </div>
                <div v-if="this.productData.images.length" class="product_page_images">
                    <section-slider-images :slider="slider_data" :storage_path="storagePath">
                    </section-slider-images>
                </div>
            </div>
        </div>

        <div v-if="attributes.length || productData.documents.length || product_packing.attribute_value" class="q10__section alternate">
            <div class="overlay"></div>
            <div class="content_wrapper product_page_lower_content">
                <div v-if="attributes.length || product_packing.attribute_value" class="product_page_attributes">
                    <base-title v-if="productData.name" :block-data="productData" :text="customFields.attributes_title"></base-title>
                    <div v-if="attributes.length" class="product_page_attributes_list">
                        <div v-for="attribute, index in attributes" :key="attribute.attribute_descriptor + '_' + index"
                            class="attribute_item">
                            <div class="attribute_item--name" v-html="attribute.attribute_name "></div>
                            <div class="attribute_item--value" v-html="attribute.attribute_value "></div>

                        </div>
                    </div>
                    <div v-if="product_packing.attribute_value" class="product_page_attribute_packaging">
                        <p class="packaging--text">{{ product_packing.attribute_name }}</p>
                        <img class="packaging--image" src="/images/icon-packing.png">
                        <div class="packaging--value" v-html="product_packing.attribute_value "></div>
                    </div>
                </div>
                <div v-if="productData.documents.length" class="product_page_documents">
                    <base-title v-if="productData.name" :text="customFields.documents_title"></base-title>
                    <div class="product_page_documents_list">
                        <div v-for="document in productData.documents" :key="document.id" class="document_item">
                            <a v-if="document.file_name" :href="storagePath + '/' + document.file_name" target="_blank" class="document_item--download">
                                <p class="document_item--name">{{ document.file_title }}</p>
                                <download-svg color="#AFAFAF"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import BaseTitle from "./common/BaseTitle.vue"
import SectionSlider from "./SectionSlider.vue"
import DownloadSvg from './svg-components/DownloadSvg.vue'

export default {
    name: 'product-page',

    props: ['productData', 'storagePath', 'customFields'],

    components: {       
        BaseTitle,
        SectionSlider,
        DownloadSvg
    },

    data() {
        return {
            attributes: [],

            product_logo: '',
            product_label: '',
            product_order: '',
            product_packing: {},
        }
    },

    computed: {
        slider_data() {

            let formated_slider = []

            for (const image of this.productData.images) {
                formated_slider.push(image.file_name)
            }

            return formated_slider;
         
        },
    },

    created() {
        // console.log('productData', this.productData);

        this.setSpecificProductData();
        this.sortAttributesByOrder();
    },

    methods: {
        setSpecificProductData() {

            for (let attribute of this.productData.attributesList) {

                if (attribute.attribute_descriptor == 'product-logo') {
                    this.product_logo = attribute.attribute_value
                } else if (attribute.attribute_descriptor == 'product-packaging') {
                    this.product_packing = {...attribute}
                } else if (attribute.attribute_descriptor == 'product-label') {
                    this.product_label = attribute.attribute_value
                } else if (attribute.attribute_descriptor == 'order') {
                    this.product_order = attribute.attribute_value
                } else {
                    this.attributes.push(attribute);
                }
            }
        },

        sortAttributesByOrder() {
            this.attributes.sort((a, b) => a.attribute_order - b.attribute_order);
        }
    }
}
</script>

<style lang="scss" scoped>
.q10__section.padding {
    padding: 20px 0;

    @media (min-width: 1024px) {
        padding: 35px 0;
    }
}

.q10__section {
    .content_wrapper {
        margin-bottom: 0;

        @media (min-width: 1024px) {
            margin-top: 0;
        }
    }
}
.product_page_upper_content {

    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (min-width: 1024px) {
        flex-direction: row;
        gap: 50px;
    }

    .product_page_info {
        display: flex;
        flex-direction: column;
        width: 100%;

        .product_page_labels {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-bottom: 20px;

            @media (min-width: 1024px) {
                margin-bottom: 50px;
            }

            .product_page_logo {
                height: 75px;

                @media (min-width: 1024px) {
                    height: 90px;
                }

                img {
                    width: auto;
                    height: 100%;
                }
            }

            .no-padding {
                margin: 0;
            }

            .product_page_label {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 63px;
                height: 63px;
                padding: 2px;
                background: #4BBF22;
                border-radius: 50%;

                span {
                    color: #FFF;
                    text-align: center;
                    font-size: 10px;
                    font-weight: 800;
                    line-height: normal;
                    letter-spacing: 0.4px;

                    transform: rotate(-15deg);
                }
            }
        }
    }

    .product_page_images {
        display: flex;
        justify-content: center;
        width: 100%;

        .content_wrapper{ 
            padding: 0 13px;
        }
    }
}

.product_page_lower_content {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (min-width: 1024px) {
        flex-direction: row;
        gap: 88px;
    }

    .product_page_attributes {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 620px;

        .product_page_attributes_list {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
            overflow: hidden;

            .attribute_item {
                display: flex;
                padding: 5px 10px;
                gap: 10px;

                &:nth-child(odd) {
                    background: rgba(175, 175, 175, 0.15);
                }

                &:nth-child(even) {
                    background: #fff;
                }

                .attribute_item--name {
                    flex-basis: 50%;

                    align-self: center;
                    color: #333;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.72px;

                    @media (min-width: 1024px) {
                        font-size: 18px;
                    }
                }

                .attribute_item--value {
                    flex-basis: 50%;

                    align-self: center;
                    color: #333;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.72px;

                    @media (min-width: 1024px) {
                        font-size: 18px;
                    }
                }
            }
        }

        .product_page_attribute_packaging {

            display: flex;
            align-items: center;
            gap: 10px;

            .packaging--text {
                color: #58595B;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.72px;

                @media (min-width: 1024px) {
                    font-size: 18px;
                }
            }

            .packaging--image {

            }

            .packaging--value {
                // max-width: 65px;
                color: #58595B;
                font-size: 14px;
                line-height: 15px;
                font-weight: 600;
            }
        }
    }

    .product_page_documents {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 620px;

        .product_page_documents_list {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .document_item {
                padding: 10px;
                border-radius: 10px;
                background-color: #fff;
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);                

                .document_item--download {
                    display: flex;               
                    align-items: center;
                    justify-content: space-between; 
                    gap: 10px;

                    .document_item--name {
                        color: #2C4E9B;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.64px;
                    }
                }
            }
        }
    }
}
</style>